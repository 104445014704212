var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "600" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4", attrs: { rounded: "lg" } },
        [
          _c("v-card-title", [_vm._v(" Eintrag bearbeiten ")]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _vm.extraCosts
                    ? _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-6" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Titel",
                                  rules: [_vm.rules.required],
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.extraCosts.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extraCosts, "title", $$v)
                                  },
                                  expression: "extraCosts.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "col-6" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Betrag",
                                  "append-icon": "mdi-currency-eur",
                                  rules: [_vm.rules.required],
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.extraCosts.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extraCosts, "price", $$v)
                                  },
                                  expression: "extraCosts.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "p-0" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", text: "" },
                  on: { click: _vm.cancel },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-close")]),
                  _vm._v("\n        Abbrechen\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-0",
                  attrs: {
                    disabled: !_vm.form,
                    type: "submit",
                    loading: _vm.isSaving,
                    color: "green",
                    text: "",
                  },
                  on: { click: _vm.editExtraCost },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-content-save"),
                  ]),
                  _vm._v("\n        Speichern\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }