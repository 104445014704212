import axios from 'axios';
import { IExportModel } from '@/shared/model/export.model';
import dayjs from "dayjs";
import {DATE_FORMAT} from "@/shared/date/filters";

const baseApiUrl = 'api/orders/export-report';

export default class HomeService {
  public downloadExportedData(exportData: IExportModel): Promise<any> {
    const url =
      `${baseApiUrl}?notReportedBefore=${exportData.notReportedBefore}` +
      (!exportData.notReportedBefore ? `&from=${dayjs(exportData.from).format(DATE_FORMAT)}&to=${dayjs(exportData.to).format(DATE_FORMAT)}` : '');
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url, { responseType: 'blob' })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
