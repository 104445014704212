var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "", flat: "", "clipped-left": "" } },
    [
      _c(
        "v-toolbar-title",
        { staticClass: "title pl-0", on: { click: _vm.toHomePage } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0", attrs: { align: "center" } },
            [
              _c("v-img", {
                attrs: { src: "content/images/logo.png", width: "35" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-3" }, [_vm._v("CB Brennstoffe")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _vm.hasAdminAuthority
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.logout } },
                [_c("v-icon", [_vm._v("mdi-power")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }