// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import * as config from './shared/config/config';
// services
import LoginService from './account/login.service';
import AccountService from './account/account.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import ZoneService from '@/entities/zone/zone.service';
import ExtraCostsService from '@/entities/extra-costs/extra-costs.service';
import ZipcodeService from '@/entities/zipcode/zipcode.service';
import HomeService from '@/core/home/home.service';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
Vue.use(Vue2Filters);
const vuetify = config.initVuetify(Vue);
const store = config.initVueXStore(Vue);

const loginService = new LoginService();
const accountService = new AccountService(store, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/zone');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/login');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,

    accountService: () => accountService,
    homeService: () => new HomeService(),
    zoneService: () => new ZoneService(),
    zipcodeService: () => new ZipcodeService(),
    extraCostsService: () => new ExtraCostsService(),
  },
  store,
  vuetify,
});
