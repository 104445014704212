import Vue from 'vue';
import Component from 'vue-class-component';
import ExtraCosts from '@/entities/extra-costs/extra-costs.vue';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router from 'vue-router';

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
import account from '@/router/account';
import entities from '@/router/entities';
import pages from '@/router/pages';
import { Authority } from '@/shared/security/authority';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/extra-costs',
      name: 'ExtraCosts',
      component: ExtraCosts,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true },
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true },
    },
    ...account,
    entities,
    ...pages,
  ],
});

export default router;
