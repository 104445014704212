import Vue from 'vue';
import Component from 'vue-class-component';
import LoginForm from '@/account/login-form/login-form.vue';

import '@/shared/config/dayjs';
import AppFooter from '@/core/app-footer/app-footer.vue';
import AppNavbar from '@/core/app-navbar/app-navbar.vue';
import Snackbar from '@/shared/components/snackbar/snackbar.vue';
import PromptDialog from '@/shared/components/prompt-dialog/prompt-dialog.vue';

@Component({
  components: { AppNavbar, AppFooter, Snackbar, PromptDialog },
})
export default class App extends Vue {
  private showDrawer = false;
  private get showNavDrawer() {
    return this.$route.name !== 'Login' && this.$route.name !== 'ResetPasswordInit' && this.$route.name !== 'ResetPasswordFinish';
  }

  private toggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  private closeDrawer() {
    this.showDrawer = false;
  }
}
