import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';

@Component
export default class AppNavbar extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  private hasAdminAuthorityValue = false;

  private toHomePage() {
    this.$route.fullPath !== '/' && this.$router.push('/');
  }

  changeTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  private logout() {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    this.$router.push('/login').then(null);
  }

  public get hasAdminAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_ADMIN')
      .then(value => {
        this.hasAdminAuthorityValue = value;
      });
    return this.hasAdminAuthorityValue;
  }

  private get redButtonActive(): boolean {
    return this.$store.getters['appStore/getRedButtonActive'];
  }
  private set redButtonActive(newStatus: boolean) {
    this.$store.dispatch('appStore/setRedButtonActive', newStatus);
  }

  private toggleDrawer() {
    this.$emit('toggleDrawer');
  }

  private closeDrawer() {
    this.$emit('closeDrawer');
  }
}
