var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("app-navbar"),
      _vm._v(" "),
      _c("v-main", { staticClass: "pa-15" }, [_c("router-view")], 1),
      _vm._v(" "),
      _c("app-footer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
      }),
      _vm._v(" "),
      _c("prompt-dialog"),
      _vm._v(" "),
      _c("snackbar"),
      _vm._v(" "),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }