var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-footer", { staticClass: "justify-center d-none" }, [
    _c("span", [_vm._v("© 2024 - CB Brennstoffe. Alle Rechte vorbehalten")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }