import { AlertType } from '@/shared/model/enumerations/alert-type.model';

export interface IAlert {
  type: AlertType | null;
  content: string | null;
  show?: boolean | null;
}

export class Alert implements IAlert {
  constructor(public type: AlertType | null, public content: string | null, public show?: boolean | null) {
    this.show = this.show ?? true;
    setTimeout(() => {
      this.show = false;
    }, 5000);
  }
}
