var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { elevation: "0" } },
    [
      _c(
        "v-card-title",
        [
          _c("h2", [_vm._v("Zusätzliche Kosten")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                color: "info",
                loading: _vm.isFetching,
                disabled: _vm.isFetching,
              },
              on: { click: _vm.retrieveExtraCosts },
            },
            [
              _c("v-icon", [_vm._v("mdi-refresh")]),
              _vm._v(" "),
              _c("span", [_vm._v("Liste aktualisieren")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.extraCosts,
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.price`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("formatPrice")(item.price)) +
                          "\n      "
                      ),
                    ]
                  },
                },
                {
                  key: `item.action`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.prepareEditExtraCosts(item.id)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "yellow" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.editDialog
        ? _c("extra-costs-update", {
            attrs: {
              extraCostsId: _vm.selectedExtraCosts,
              showDialog: _vm.editDialog,
            },
            on: {
              updateDone: _vm.updateDone,
              "update:showDialog": function ($event) {
                _vm.editDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.editDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }