import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, PropSync, Watch } from 'vue-property-decorator';
import ExtraCostsService from '@/entities/extra-costs/extra-costs.service';
import { IExtraCosts } from '@/shared/model/extra-costs.model';
import { Alert, IAlert } from '@/shared/model/alert.model';
import { AlertType } from '@/shared/model/enumerations/alert-type.model';

@Component
export default class ExtraCostsUpdate extends Vue {
  @Inject('extraCostsService') private extraCostsService: () => ExtraCostsService;

  @Prop() extraCostsId: number;
  @PropSync('showDialog') show: boolean;

  public extraCosts: IExtraCosts = null;
  private isSaving = false;
  private form = false;
  private rules = {
    required: value => !!value || 'Dies ist ein Pflichtfeld.',
  };

  async mounted() {
    this.retrieveExtraCosts(this.extraCostsId);
  }

  public cancel(): void {
    this.show = false;
  }

  private async editExtraCost() {
    this.isSaving = true;
    try {
      await this.extraCostsService().update(this.extraCosts);
      this.$emit('updateDone');
      this.snackbarContent.push(new Alert(AlertType.SUCCESS, 'Eingaben wurden erfolgreich aktualisiert'));
      this.show = false;
    } catch (error) {
      this.snackbarContent.push(new Alert(AlertType.ERROR, 'Es ist ein Fehler passiert'));
    }

    this.isSaving = false;
  }

  public retrieveExtraCosts(extraCostsId): void {
    this.extraCostsService()
      .find(extraCostsId)
      .then(res => {
        this.extraCosts = res;
      })
      .catch(err => {
        this.snackbarContent.push(new Alert(AlertType.ERROR, 'Es ist ein Fehler passiert'));
      });
  }

  private get snackbarContent(): IAlert[] {
    return this.$store.getters['appStore/getSnackbarContent'];
  }

  private set snackbarContent(newSnackbarContent: IAlert[]) {
    this.$store.dispatch('appStore/setSnackbarContent', newSnackbarContent).then(null);
  }
}
