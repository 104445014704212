import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from 'vue-property-decorator';
import ExtraCostsService from '@/entities/extra-costs/extra-costs.service';
import { IExtraCosts } from '@/shared/model/extra-costs.model';
import ExtraCostsUpdate from './extra-costs-update.vue';
import { Alert, IAlert } from '@/shared/model/alert.model';
import { AlertType } from '@/shared/model/enumerations/alert-type.model';

@Component({
  components: { ExtraCostsUpdate },
})
export default class ExtraCosts extends Vue {
  @Inject('extraCostsService') private extraCostsService: () => ExtraCostsService;

  private extraCosts: IExtraCosts[] = [];
  private isFetching = false;
  private deleteDialog = false;
  private removeId: number = null;
  private editDialog = false;
  private removeEntity = null;
  private selectedExtraCosts = null;
  private headers = [
    { text: 'Titel', value: 'title' },
    { text: 'Betrag', value: 'price' },
    { text: 'Aktion', value: 'action' },
  ];

  async mounted() {
    await this.retrieveExtraCosts();
  }

  private async retrieveExtraCosts() {
    this.isFetching = true;
    try {
      const res = await this.extraCostsService().retrieve();
      this.extraCosts = res.data;
    } catch (err) {
      this.snackbarContent.push(new Alert(AlertType.ERROR, err.response));
    } finally {
      this.isFetching = false;
    }
  }

  private async removeExtraCosts() {
    try {
      await this.extraCostsService().delete(this.removeId);
      const message = 'A ExtraCosts is deleted with identifier ' + this.removeId;
      this.snackbarContent.push(new Alert(AlertType.SUCCESS, message));
      this.removeId = null;
      await this.retrieveExtraCosts();
    } catch (error) {
      this.snackbarContent.push(new Alert(AlertType.SUCCESS, error.response));
    }
  }

  private prepareEditExtraCosts(id: number) {
    this.selectedExtraCosts = id;
    this.editDialog = true;
  }

  private async updateDone(): Promise<void> {
    this.editDialog = false;
    await this.retrieveExtraCosts();
  }
  private get snackbarContent(): IAlert[] {
    return this.$store.getters['appStore/getSnackbarContent'];
  }

  private set snackbarContent(newSnackbarContent: IAlert[]) {
    this.$store.dispatch('appStore/setSnackbarContent', newSnackbarContent).then(null);
  }
}
